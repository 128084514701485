/**
 * Language Switcher JavaScript
 * 
 * This is a minimal script to make the language switcher work
 * without requiring the full app.js bundle.
 */

document.addEventListener('DOMContentLoaded', function() {
    // Initialize dropdown toggles for language switcher
    const dropdownToggles = document.querySelectorAll('.dropdown-toggle');
    
    dropdownToggles.forEach(toggle => {
        toggle.addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            
            // Find the parent dropdown element
            const parent = this.closest('.dropdown');
            
            // Toggle the dropdown menu
            const dropdownMenu = parent.querySelector('.dropdown-menu');
            if (dropdownMenu) {
                const isVisible = dropdownMenu.classList.contains('show');
                
                // Close all other dropdowns first
                document.querySelectorAll('.dropdown-menu.show').forEach(menu => {
                    menu.classList.remove('show');
                });
                
                // Toggle this dropdown
                if (!isVisible) {
                    dropdownMenu.classList.add('show');
                    
                    // Add click outside listener to close dropdown
                    setTimeout(() => {
                        document.addEventListener('click', closeDropdowns);
                    }, 0);
                }
            }
        });
    });
    
    // Function to close all dropdowns when clicking outside
    function closeDropdowns(e) {
        if (!e.target.closest('.dropdown')) {
            document.querySelectorAll('.dropdown-menu.show').forEach(menu => {
                menu.classList.remove('show');
            });
            document.removeEventListener('click', closeDropdowns);
        }
    }
}); 